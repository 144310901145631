<template>
  <div class="container-fluid">

    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <div class="row">
          <div class="col-xs-12 col-sm-6 text-xs-center text-sm-left"><h4>Active Console Sessions</h4></div>
          <div class="col-xs-12 col-sm-6 text-xs-center text-sm-right"><button class="btn nw-btn-outline-info" @click="loadSessions()"><i class="fa fa-refresh"></i> Refresh</button></div>
        </div>

      </div>
      <div class="card-body text-left">
        <table class="table table-hover">
          <thead>
            <th>Username</th>
            <th>Name</th>
            <th>Expires</th>
            <th>ID</th>
            <th>Terminate</th>
          </thead>
          <tbody>
            <tr v-for="item in arr">
              <td>{{ item.userName}}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.expires}}</td>
              <td>{{ item.userID }}</td>
              <td><a class="text-danger"><i class="fa fa-times"></i> Log Out</a></td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>

  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'


export default {
  mounted () {
    this.loadSessions()
  },
  data () {
    return {
      arr: [

      ]
    }
  },
  methods: {
    loadSessions () {
      NetClient.doRequest('rest/session', { action: 'getActiveSessions'}).then(msg => {
        if(typeof msg.data != 'undefined') this.arr = msg.data

      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
